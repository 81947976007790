.Active {
  background: white;
  box-shadow: 0 12px 20px -10px #dcdcdc, 0 4px 20px 0px rgba(0, 0, 0, 0.12),
    0 7px 8px -5px rgba(0, 0, 0, 0.12);
  transition: all 100ms linear;
  border-radius: 3px;
  width: 100%;
  margin: 0 auto;
  color: #dcdcdc !important;
}

.Active .ItemText {
  color: grey !important;
}

.Active svg {
  color: #dcdcdc !important;
}
