.Auth {
  width: 100%;
  height: 100vh;
}

.AuthFormContainer {
  display: table;
  width: 100%;
  height: 100%;
}

.AuthFormSubContainer {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.AuthForm {
  margin-left: auto;
  margin-right: auto;
  max-width: 70%;
  background: white;
  padding: 40px;
  text-align: center;
}

.SubmitButton {
  padding: 10px 40px !important;
}

.Logo {
  width: 230px;
  margin: -30px auto 15px auto;
}

@media (min-width: 960px) {
  .AuthForm {
    max-width: 40%;
  }
}
