
  .AutoCompleteText {
    color: gray;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    line-height : 1.42857143;
    padding: 12px 8px;
    vertical-align : middle;
  }

  .Logo {
    box-sizing: border-box;
    border-radius: 5px;
  }
  
  .Logo img {
    max-width: 20px;
  }
  